import React, { Component } from 'react';
import './App.css';

export default class StripePayment extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      webcontent: '(null)',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elWebcontent = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="StripePayment">
        <div className="layoutFlow">
          <div className="elWebcontent">
            <iframe style={style_elWebcontent} src={this.state.webcontent}  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
