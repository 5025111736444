import React, { Component } from 'react';
import './App.css';
import ChatItem from './ChatItem';
import btn_icon_686486 from './images/btn_icon_686486.png';
import btn_icon_916879 from './images/btn_icon_916879.png';
import btn_icon_back_chatgroups from './images/btn_icon_back_chatgroups.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ChatGroupsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['chats'];
      let serviceOptions = this.props.appActions.serviceOptions_chats;
      if ( !this.props.appActions.dataSheetLoaded['chats']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("chats", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebaseConnection(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['chats'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elFab = (ev) => {
    // Go to screen 'Order Status'
    this.props.appActions.goToScreen('orderstatus', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elIconButton = (ev) => {
    // Go to screen 'Create a Chat Group'
    this.props.appActions.goToScreen('createachatgroup', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const dataSheet_chats = this.props.dataSheets['chats'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('chats').items);
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elFab = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_916879+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen ChatGroupsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <ChatItem dataSheetId={'chats'} dataSheetRow={row} {...{ 'chatName': row['chatName'], 'document_key': row['document_key'], }} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elFab">
            <Button className="actionFont" style={style_elFab}  variant="fab" color="accent" onClick={this.onClick_elFab} >
              <img src={btn_icon_686486} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Chat Groups</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_chatgroups} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
        </div>
      </div>
    )
  }
  
}
