import React, { Component } from 'react';
import './App.css';
import ChatMessage from './ChatMessage';
import ChatSendMessageComponent from './ChatSendMessageComponent';
import btn_icon_back_chatroom from './images/btn_icon_back_chatroom.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ChatroomScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['chatHistory'];
      let serviceOptions = this.props.appActions.serviceOptions_chatHistory;
      if ( !this.props.appActions.dataSheetLoaded['chatHistory']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("chats/$slot('ds_SlotSelectedChat')/messages", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebaseConnection(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['chatHistory'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton = (ev) => {
    // Go to screen 'Video Call'
    this.props.appActions.goToScreen('videocall', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const dataSheet_chatHistory = this.props.dataSheets['chatHistory'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
     };
    const style_elButton = {
      marginRight:'1vh',
      paddingRight: '1vh',
      paddingLeft:'1vh',
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
      marginLeft: 'auto', 
      float: 'right'
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('chatHistory').items);
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_elRectangle = {
      background: 'rgba(254, 255, 255, 1.000)',
     };
    
    return (
      <div className="AppScreen ChatroomScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elButton">
          <card></card>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <ChatMessage dataSheetId={'chatHistory'} dataSheetRow={row} {...{ 'messageBody': row['messageBody'], 'senderName': row['senderName'], 'timestamp': row['timestamp'], }} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div style={{marginBottom:0}}className="elRectangle">
            <div style={style_elRectangle} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Chatroom</div>  
          <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_chatroom} alt="" style={{width: '50%'}} /></div>
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.chatroom_button_880906}
            </Button>
          </div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elChatSendMessageComponent">
              <ChatSendMessageComponent ref={(el)=> this._elChatSendMessageComponent = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
