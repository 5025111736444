import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class AddChatComponent extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      fieldChatName: '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  textInputChanged_fieldChatName = (event) => {
    this.setState({fieldChatName: event.target.value});
  }
  
  onClick_elButton = (ev) => {
    this.sendData_button_to_chats();
  
    // Go to screen 'Chat Groups'
    this.props.appActions.goToScreen('chatgroups', { transitionId: 'fadeIn' });
  
  }
  
  
  sendData_button_to_chats = () => {
    const dataSheet = this.props.appActions.getDataSheet('chats');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      chatName: this.state.fieldChatName,
    };
    if (this.props.dataSheetId === dataSheet.id) {
      this.props.appActions.updateInDataSheet('chats', row);
    } else {
      this.props.appActions.addToDataSheet('chats', row);
    }
  }
  
  
  render() {
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elFieldChatName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AddChatComponent appBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.props.locStrings.addchatcomponent_text_210643}</div>
            </div>
          </div>
          
          <div className="elFieldChatName">
            <input className="baseFont" style={style_elFieldChatName} type="text" placeholder={this.props.locStrings.addchatcomponent_field_128765} onChange={this.textInputChanged_fieldChatName} value={this.state.fieldChatName}  />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.addchatcomponent_button_854470}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
