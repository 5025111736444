import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class WelcomeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      fieldUserName: '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elButton = (ev) => {
    let newVal = this.state.fieldUserName;
    this.props.appActions.updateDataSlot('ds_SlotUsername', newVal);
  
    // Go to screen 'Chat Groups'
    this.props.appActions.goToScreen('chatgroups', { transitionId: 'fadeIn' });
  
  }
  
  
  textInputChanged_fieldUserName = (event) => {
    this.setState({fieldUserName: event.target.value});
  }
  
  onClick_elButtonCopy = (ev) => {
    let newVal = this.state.fieldUserName;
    this.props.appActions.updateDataSlot('(null)', newVal);
  
    // Go to screen 'Auth Screen'
    this.props.appActions.goToScreen('authscreen', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {
      marginTop: '20vh'
    };
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
     };
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFieldUserName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: '#feffff',
      textAlign: 'center',
     };
    const style_elButtonCopy = {
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen WelcomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          {/* <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.welcome_button_649165}
            </Button>
          </div> */}
          
          {/* <div className="elFieldUserName">
            <input className="baseFont" style={style_elFieldUserName} type="text" placeholder={this.props.locStrings.welcome_field_482977} onChange={this.textInputChanged_fieldUserName} value={this.state.fieldUserName}  />
          </div> */}
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>Welcome! Please sign in</div>
            </div>
          </div>
          
          <div className="elButtonCopy">
            <Button className="actionFont" style={style_elButtonCopy}  color="accent" onClick={this.onClick_elButtonCopy} >
              {this.props.locStrings.signupscreen_buttoncopy_904765}
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Welcome Screen</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }></div>
        </Appbar>
        
      </div>
    )
  }
  
}
