import React, { Component } from 'react';
import './App.css';
import btn_icon_266377 from './images/btn_icon_266377.png';

export default class ChatMessage extends Component {

  // Properties used by this component:
  // messageBody, senderName, timestamp

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elIconButton = (ev) => {
    // Remove row from connected sheet
    this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
     };
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: '#5e5e5e',
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
     };
    
    const value_text = this.props.senderName;
    
    const style_elText = {
      fontSize: 15.2,
      color: 'black',
      textAlign: 'left',
     };
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_266377+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '88.335%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_textCopy = this.props.messageBody;
    
    const style_elTextCopy = {
      color: 'black',
      textAlign: 'left',
     };
    let transformPropValue_textCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      
      return input;
    }
    
    const value_textCopy2 = transformPropValue_textCopy2(this.props.timestamp);
    
    const style_elTextCopy2 = {
      fontSize: 15.2,
      color: 'black',
      textAlign: 'left',
     };
    
    return (
      <div className="ChatMessage">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
          
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="systemFontBold" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.chatmessage_text_597844}</span>)}</div>
            </div>
          </div>
          
          <div className="elIconButton">
            <button className="actionFont" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
          </div>
          
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.props.locStrings.chatmessage_textcopy_430295}</span>)}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="systemFontItalic" style={style_elTextCopy2}>
              <div>{value_textCopy2 !== undefined ? value_textCopy2 : (<span className="propValueMissing">{this.props.locStrings.chatmessage_textcopy2_716375}</span>)}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
