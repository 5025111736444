import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/VideoCallScreen_elImage_647697.jpg';
import btn_icon_back_videocall from './images/btn_icon_back_videocall.png';
import * as io from 'socket.io-client'
import RTCMultiConnection from 'rtcmulticonnection';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';



// Properties used by this component:
// appActions, deviceInfo
function VideoCallScreen(props) {
  window.io = io
  const [roomCode, setRoomCode] = React.useState("aaaa");
  const [connected, setConnected] = React.useState(false);

  var connection = new RTCMultiConnection();
  //connection.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';
  connection.socketURL = 'https://evening-plains-35151.herokuapp.com/';
	//connection.socketURL = "http://localhost:9001/"
  connection.session = {
    audio: true,
    video: true
  };
  connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true
  };
  connection.onstream = function (event) {
    var video = event.mediaElement;
    video.controls = false;
    video.style.cssText = `-moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1); -o-transform: scale(-1, 1);
  transform: scale(-1, 1); filter: FlipH; width: 70vh;`;
    document.getElementById("callers").appendChild(event.mediaElement);
  };
  const joinRoom = () => {
    if (!roomCode)
      return;
    connection.openOrJoin(roomCode);
    setConnected(true);
  }

  const leaveRoom = () => {
    connection.getAllParticipants().forEach(function (pid) {
      connection.disconnectWith(pid);
    });

    // stop all local cameras
    connection.attachStreams.forEach(function (localStream) {
      localStream.stop();
    });

    // close socket.io connection
    connection.closeSocket();

    setConnected(false);
    console.log(connection);
  };


  let layoutFlowStyle = {};
  let baseStyle = {};
  if (props.transitionId && props.transitionId.length > 0 && props.atTopOfScreenStack && props.transitionForward) {
    baseStyle.animation = '0.25s ease-in-out ' + props.transitionId;
  }
  if (!props.atTopOfScreenStack) {
    layoutFlowStyle.height = '100vh';
    layoutFlowStyle.overflow = 'hidden';
  }

  const style_elBackground = {
    width: '100%',
    height: '100%',
  };
  const style_elBackground_outer = {
    backgroundColor: '#424242',
  };
  const style_elButton = {
    marginRight:'1vh',
    paddingRight: '1vh',
    paddingLeft:'1vh',
    display: 'block',
    color: 'white',
    textAlign: 'center',
    cursor: 'pointer',
    pointerEvents: 'auto',
    marginLeft: 'auto', 
    float: 'right'
   };

  return (

    <div className="AppScreen VideoCallScreen" style={baseStyle}>

      <div className="background">
        <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
          <div className="appBg" style={style_elBackground} />
        </div>
      </div>

      {/* <div className="layoutFlow" style={layoutFlowStyle}>
        <div className="hasNestedComps elComp">
          <div>
         
          </div>
        </div>
      </div> */}
      <Appbar className="navBar">
        <div className="title">Video Call</div>  <div className="backBtn" onClick={(ev) => {props.appActions.goBack() }}><img src={btn_icon_back_videocall} alt="" style={{ width: '50%' }} /></div>
        <Button style={style_elButton} color="accent" onClick={() => { joinRoom(); }}>Join Room A </Button>
      </Appbar>

     
      {/* <Button onClick={() => { leaveRoom(); }}>Hang up</Button> */}

      <div id="callers" style={{
        display: 'flex',
        alignItems: 'center',
        height: '99vh',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        marginTop: '10px'
      }}></div>

    </div>
  );
}

export default VideoCallScreen;
