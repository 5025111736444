import React, { Component } from 'react';
import './App.css';
import img_elImageCopy from './images/RestaurantMenuScreen_elImageCopy_531557.png';
import img_elImageCopy3 from './images/RestaurantMenuScreen_elImageCopy3_907436.jpg';
import Menu from './Menu';
import btn_icon_back_restaurantmenu from './images/btn_icon_back_restaurantmenu.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class RestaurantMenuScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elComp = (ev) => {
    // Go to screen 'Payment'
    this.props.appActions.goToScreen('payment', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
     };
    const style_elImageCopy = {
      backgroundImage: 'url('+img_elImageCopy+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elImageCopy3 = {
      backgroundImage: 'url('+img_elImageCopy3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elComp = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen RestaurantMenuScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elImageCopy">
            <div style={style_elImageCopy} />
          </div>
          
          <div className="elImageCopy3">
            <div style={style_elImageCopy3} />
          </div>
          
          <div className="hasNestedComps elComp">
            <div style={style_elComp} onClick={this.onClick_elComp} >
              <Menu ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Restaurant Menu</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_restaurantmenu} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
      </div>
    )
  }
  
}
