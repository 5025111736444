import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "welcome_button_649165";
    item['en'] = "Continue";
    
    item = {};
    this.items.push(item);
    item['key'] = "welcome_text_164383";
    item['en'] = "Welcome, what’s your name?";
    
    item = {};
    this.items.push(item);
    item['key'] = "welcome_field_482977";
    item['en'] = "Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatitem_text_101470";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatitem_text_699962";
    item['en'] = "(Chatroom name)";
    
    item = {};
    this.items.push(item);
    item['key'] = "addchatcomponent_text_210643";
    item['en'] = "Enter the chat name";
    
    item = {};
    this.items.push(item);
    item['key'] = "addchatcomponent_button_854470";
    item['en'] = "Create";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatrooms_iconbutton_916879";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatmessage_text_597844";
    item['en'] = "Sender";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatmessage_textcopy_430295";
    item['en'] = "Message";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatmessage_textcopy2_716375";
    item['en'] = "time";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_691609";
    item['en'] = "Send";
    
    item = {};
    this.items.push(item);
    item['key'] = "signupscreen_buttoncopy_904765";
    item['en'] = "Sign in";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatroom_button_880906";
    item['en'] = "Video";
    
    item = {};
    this.items.push(item);
    item['key'] = "videocall_button_809920";
    item['en'] = "Hang up";
    
    item = {};
    this.items.push(item);
    item['key'] = "videocall_buttoncopy_861995";
    item['en'] = "Mute";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatsendmessagecomponent_button2_705411";
    item['en'] = "Order Food";
    
    item = {};
    this.items.push(item);
    item['key'] = "videocall2_buttoncopy_387292";
    item['en'] = "Mute";
    
    item = {};
    this.items.push(item);
    item['key'] = "videocall2_button_807674";
    item['en'] = "Hang up";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatgroups2_button_187678";
    item['en'] = "Chatrooms";
    
    item = {};
    this.items.push(item);
    item['key'] = "chatroom_button2_1004304";
    item['en'] = "New button";
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
