import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class ChatSendMessageComponent extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      textareaMessage: '',
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  textAreaChanged_textareaMessage = (event) => {
    this.setState({textareaMessage: event.target.value});
  }
  
  onClick_elButton = (ev) => {
    this.sendData_button_to_chatHistory();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      textareaMessage: "",
    });
  
  }
  
  
  onClick_elButton2 = (ev) => {
    // Go to screen 'Video Call'
    this.props.appActions.goToScreen('videocall', { transitionId: 'fadeIn' });
  
  }
  
  
  sendData_button_to_chatHistory = () => {
    const dataSheet = this.props.appActions.getDataSheet('chatHistory');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      senderName: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_SlotUsername'] : ''),
      messageBody: this.state.textareaMessage,
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      var currentDate = new Date();
      input.timestamp = currentDate.getTime();
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      this.props.appActions.updateInDataSheet('chatHistory', row);
    } else {
      this.props.appActions.addToDataSheet('chatHistory', row);
    }
  }
  
  
  render() {
    
    const style_elTextareaMessage = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButton2 = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ChatSendMessageComponent appBg">
        <div className="layoutFlow">
          <div className="elTextareaMessage">
            <textarea className="baseFont" style={style_elTextareaMessage}  placeholder={this.props.locStrings.component1_textarea_719683} onChange={this.textAreaChanged_textareaMessage} value={this.state.textareaMessage}  />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.props.locStrings.component1_button_691609}
            </Button>
          </div>
          
          <div className="elButton2">
            <Button className="actionFont" style={style_elButton2}  color="accent" onClick={this.onClick_elButton2} >
              {this.props.locStrings.chatsendmessagecomponent_button2_705411}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
