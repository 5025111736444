import React, { Component } from 'react';
import './App.css';

export default class ChatItem extends Component {

  // Properties used by this component:
  // chatName, document_key

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elText = (ev) => {
    let newVal = this.props.document_key;
    this.props.appActions.updateDataSlot('ds_SlotSelectedChat', newVal);
  
    // Go to screen 'Chatroom'
    this.props.appActions.goToScreen('chatroom', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elCard_outer = {
      backgroundColor: '#5e5e5e',
      boxShadow: '0.0px 2.3px 18px rgba(0, 0, 0, 0.1600)',
     };
    
    const value_text = this.props.chatName;
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ChatItem appBg">
        <div className="layoutFlow">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText} onClick={this.onClick_elText} >
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.props.locStrings.chatitem_text_699962}</span>)}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
