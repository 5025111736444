import React, { Component } from 'react';
import './App.css';

export default class OrderStatusPage extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    
    return (
      <div className="OrderStatusPage">
        <div className="layoutFlow">
          <div className="elList">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
