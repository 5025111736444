import React, { Component } from 'react';
import './App.css';
import btn_icon_back_authscreen from './images/btn_icon_back_authscreen.png';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';
import Button from 'muicss/lib/react/button';



export default class AuthScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo
  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    //signInSuccessUrl: ,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  };

  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false
    };

  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => this.setState({ isSignedIn: !!user })
    );
    if(firebase.auth().currentUser!=null)
    this.props.appActions.updateDataSlot('ds_SlotUsername', firebase.auth().currentUser.displayName);
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    let layoutFlowStyle = {
      color: 'white',
      marginTop: '20vh',
      position: 'relative',
      textAlign: 'center',
      display: 'block',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
    };
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out ' + this.props.transitionId;
    }
    if (!this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }

    const style_elBackground = {
      width: '100%',
      height: '100%',
    };
    const style_elBackground_outer = {
      backgroundColor: '#424242',
    };
    const style_elButton = {
      color: 'accent',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
      paddingLeft: '5vh',
      paddingRight: '5vh'
    };


    if (!this.state.isSignedIn) {
      return (
        <div className="AppScreen AuthScreen" style={baseStyle}>
          <div className="background">
            <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
              <div className="appBg" style={style_elBackground} />
            </div>
          </div>

          <div className="layoutFlow" style={layoutFlowStyle}>
            <p >Please sign-in:</p>
            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
          </div>

          <Appbar className="navBar">
            <div className="title">Auth Screen</div>  <div className="backBtn" onClick={(ev) => { this.props.appActions.goBack() }}><img src={btn_icon_back_authscreen} alt="" style={{ width: '50%' }} /></div>
          </Appbar>


        </div>
      )
    } else {
      
      return (
        <div className="AppScreen AuthScreen" style={baseStyle}>
          <div className="background">
            <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
              <div className="appBg" style={style_elBackground} />
            </div>
          </div>

          <div className="layoutFlow" style={layoutFlowStyle}>
            <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
            <Button className="actionFont" style={style_elButton} color="accent" onClick={() =>
              this.props.appActions.goToScreen('chatgroups', { transitionId: 'fadeIn' })}>
              Go to Chat Groups
            </Button>
            <div style={{marginTop:'3vh'}}>
            <Button className="actionFont" style={style_elButton} color="accent" onClick={() =>
              firebase.auth().signOut()}>Sign-out
            </Button>
            </div>



          </div>

          <Appbar className="navBar">
            <div className="title">Auth Screen</div>  <div className="backBtn" onClick={(ev) => { this.props.appActions.goBack() }}><img src={btn_icon_back_authscreen} alt="" style={{ width: '50%' }} /></div>
          </Appbar>


        </div>

      )
    }
  }


}
